import React from "react";
import { graphql, Link } from "gatsby";
import classnames from "classnames";

import Layout from "../components/layout";
import SEO from "../components/seo";
import RichText from "../components/richtext";
import BlogMeta from "../components/blog/meta";
import Slice from "../components/slice";

const BlogPostImage = ({ url, alt, dimensions }) => {
  const isPortrait = dimensions.height > dimensions.width;
  return (
    <img
      src={url}
      alt={alt}
      className={classnames("self-start mb-4", {
        "max-h-screen md:max-h-3/4": isPortrait
      })}
    />
  );
};

const BlogPostTemplate = ({ data }) => {
  const {
    post_title,
    post_image,
    post_date,
    post_body,
    post_type,
    author,
    body
  } = data.prismicBlogPost.data;
  return (
    <Layout>
      <SEO title={`${post_title.text} | Beaver Meadow Community Blog`} />
      <div className="max-w-4xl mx-auto w-full mb-10">
        <div className="mb-4">
          <BlogMeta post_type={post_type} post_date={post_date} />
          <h1 className="text-2xl md:text-4xl font-serif pb-2">
            {post_title.text}
          </h1>
          {author && <p className="font-serif">By {author}</p>}
        </div>
        <div
          className={classnames("flex flex-col", {
            "md:flex-row-reverse":
              post_image.dimensions &&
              post_image.dimensions.width < post_image.dimensions.height
          })}
        >
          {post_image.url && <BlogPostImage {...post_image} />}
          {post_body.html && (
            <div className="md:pr-4">
              <RichText html={post_body.html} />
            </div>
          )}
        </div>
        <div>
          {body.map((slice, index) => (
            <Slice {...slice} key={index} />
          ))}
        </div>
        <Link
          to="/blog"
          className="btn border w-56 inline-block hover:bg-sage hover:text-white mt-8"
        >
          Blog
        </Link>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostByUid($uid: String!) {
    prismicBlogPost(uid: { eq: $uid }) {
      data {
        post_title {
          text
        }
        post_image {
          url
          alt
          dimensions {
            height
            width
          }
        }
        post_date
        post_body {
          html
        }
        post_type
        author
        body {
          ... on PrismicBlogPostBodyImageGallery {
            slice_type
            items {
              image {
                url
                dimensions {
                  height
                  width
                }
                thumbnails {
                  full_width {
                    url
                  }
                  thumbnail {
                    url
                  }
                }
                alt
              }
            }
          }
        }
      }
    }
  }
`;

export default BlogPostTemplate;
